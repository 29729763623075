import * as React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import Field from './Field'
import handleSubmit from './handleSubmit'
import clsx from 'clsx'
import * as css from './RegisterForm.module.css'

const RegisterForm = () => {
  const [$complete, set$complete] = React.useState(false)
  const [$disabled, set$disabled] = React.useState(true)

  return (
    <div className={css.root}>
      {!$complete && (
        <form
          name="cpsregistration"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={e => handleSubmit(e, [set$complete])}
          className={css.form}
        >
          <input type="hidden" name="form-name" value="cpsregistration"/>

          <div className={css.fields}>
            <Field label="First Name" name="firstname" autoComplete="on" required />
            <Field label="Last Name" name="lastname" required />
            <Field label="Email" name="email" type="email" required />
            <Field label="Password" name="password" type="password" required />
            <Field label="Confirm Password" name="passwordconfirmation" type="password" required />
            <Field label="Company Name" name="company" autoComplete="on" />
            <Field label="Street Address" name="address1" autoComplete="on" />
            <Field label="Suite or Unit #" name="address2" autoComplete="on" />
            <Field label="City" name="city" autoComplete="on" />
            <Field label="State">
              <select name="state" autoComplete="on">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </Field>
            <Field label="Zip Code" name="postalcode" autoComplete="on" />
            <Field label="Phone" name="phone" autoComplete="on" required />
            <Field label="Fax" name="fax" autoComplete="on" />
            <Field
              label="Producer Group Affiliation"
              name="producergroupaffiliation"
              description="Please list your producer group affiliation, if any?"
              autoComplete="on"
            />
            <Field
              label="Life License"
              name="lifelicense"
              description="How long have you been licensed to sell life insurance?"
              autoComplete="on"
              placeholder="e.g. 3 years"
            />
            <Field
              label="Lines of Business"
              name="businesslines[]"
              description="Please select the products you are actively selling."
            >
              <select id="businesslines[]" name="businesslines[]" multiple>
                <option value="annuities">Annuities</option>
                <option value="disablity">Disability Income</option>
                <option value="life">Life</option>
                <option value="longtermcare">Long Term Care</option>
              </select>
            </Field>
            <Field label="Are you securities licensed?" name="licensed" autoComplete="on" placeholder="Yes / No" />
            <Field label="Broker Dealer" name="brokerdealer" autoComplete="on" />
            <Field label="Are you an RIA?" name="ria" autoComplete="on" placeholder="Yes / No" />
            {/* <Field
              label="Text Messaging"
              name="textmsg"
              autoComplete="on"
              placeholder="Yes / No"
            />
            <Field label="Text Messaging Phone #" name="textmsgphone" placeholder="Phone #" />
            <p className={css.txtmsg}>
              <small>I consent to receive updates from CPS Insurance Services via text message at the phone number provided above. Msg & data rates may apply. Msg frequency varies.</small>
            </p> */}

            <div className={css.colspan}>
              <GoogleReCaptcha onVerify={() => set$disabled(false)} />
            </div>

            <div className={css.colspan}>
              <button type="submit" className={`${css.action} action full`} disabled={$disabled}>
                Register
              </button>
            </div>
          </div>
        </form>
      )}

      <div id="formMessage" className={clsx(css.colspan, css.message)} />
    </div>
  )
}

export default RegisterForm
