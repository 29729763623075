import slugify from 'slugify'

export default function generateUsername(email, max = 60, hashSize = 5, splitter = '_') {
  const slugified = slugify(email.split(/[+@]/)[0], {
    remove: /\./g,
    lower: true,
  })

  return slugified.slice(0, max - hashSize - splitter.length) + splitter + hash(hashSize)
}

function hash(size) {
  let result = ''
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < size; i++) {
    result += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
 }
 return result
}
