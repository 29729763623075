import * as React from 'react'
import * as css from './Field.module.css'

const Field = ({ label, name, description, children, required, ...props }) => <>
  <div className={css.txt}>
    <label htmlFor={name} className={css.label}>
      {label}
      {required && <span className={css.required}>*</span>}
    </label>

    {!!description && (
      <p className={css.desc}><small>{description}</small></p>
    )}
  </div>

  <div className={css.input}>
    {children || (
      <input type="text"
        id={name}
        name={name}
        required={required}
        {...props}
      />
    )}
  </div>
</>

export default Field
