import generateUsername from './generateUsername'

function handleSubmit(e, [set$complete]) {
  e.preventDefault()

  const formData = new FormData(e.target)
  const resultsMessageContainer = document.getElementById('formMessage')

  resultsMessageContainer.innerHTML = ''

  if (formData.get('password') !== formData.get('passwordconfirmation')) {
    resultsMessageContainer.innerHTML = "<p>Passwords don't match</p>"
    return
  }

  fetch(`https://${process.env.GATSBY_AUTH0_DOMAIN}/dbconnections/signup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(prepAuth0Data(formData)),
  })
    .then((res) => res.json())
    .then((data) => handleResponse(data, formData, [set$complete]))
    .catch(console.error)
}

function prepAuth0Data(formData) {
  return {
    client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
    connection: process.env.GATSBY_AUTH0_CONNECTION,
    email: formData.get('email'),
    name: [formData.get('firstname'), formData.get('lastname')]
      .filter(Boolean)
      .join(' '),
    username: generateUsername(formData.get('email')),
    password: formData.get('password'),
    given_name: formData.get('firstname'),
    family_name: formData.get('lastname'),
    user_metadata: {
      Company: formData.get('company') || '',
      Address:
        [
          formData.get('address1') || '',
          formData.get('address2') || '',
          formData.get('city') || '',
          formData.get('state') || '',
          formData.get('postalcode') || '',
        ]
          .filter(Boolean)
          .join(', ') || '',
      Phone: formData.get('phone') || '',
      Fax: formData.get('fax') || '',
      'Product Group Affiliation':
        formData.get('producergroupaffiliation') || '',
      'Life License': formData.get('lifelicense') || '',
      'Lines of Business': formData.getAll('businesslines[]').join() || '',
      'Securities Licensed': formData.get('licensed') || '',
      'Broker Dealer': formData.get('brokerdealer') || '',
      RIA: formData.get('ria') || '',
      // 'Text Messaging': formData.get('txtmsg') || '',
      // 'Text Messaging Phone #': formData.get('txtmsgphone') || '',
    },
  }
}

function handleResponse(data, formData, [set$complete]) {
  const resultsMessageContainer = document.getElementById('formMessage')

  // success
  if (data.statusCode === 200 || !!data.email) {
    resultsMessageContainer.innerHTML = `<h3>Registration Successful</h3>
      <p>Thank you for Registering at the CPS Insurance Website.</p>
      <p>A CPS Marketing Representative will be contacting shortly to discuss opportunities and how we can best support your business</p>`

    // post form
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: new URLSearchParams(formData).toString(),
    }).catch(console.error)

    set$complete(true)
  }

  // error
  else if (data.statusCode === 400 && data.code === 'invalid_password') {
    resultsMessageContainer.innerHTML = `<h3>${data.message}</h3><p><em>${data.policy}</em></p><p>Please update your password and retry.</p>`
  } else if (
    data.statusCode === 400 &&
    ['invalid_signup', 'user_exists'].includes(data.code)
  ) {
    resultsMessageContainer.innerHTML =
      '<h3>Invalid signup</h3><p>User email may already exist.</p>'
  } else {
    resultsMessageContainer.innerHTML = `<p>Registration failed</p>`
  }
}

export default handleSubmit
