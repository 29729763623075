import * as React from 'react'
import * as css from './NewsletterSignup.module.css'

export default function NewsletterSignup() {
  return (
    <section className="container-sm padding">
      <div className="richtext">
        <h2 className="h2">Subscribe to our newsletter</h2>
        <p>
          The EPICC Center is the online source for financial professionals,
          designed to provide them with the essential elements they need to run
          their practice and increase their bottom line including breaking news,
          carrier / product information, market trends, sales ideas, marketing
          tools and resources and more.
        </p>
        <p>
          Get these updates delivered directly to your inbox by signing up for
          our newsletter. Just enter your email and click the subscribe button.
        </p>
      </div>
      <div
        className={css.form}
        dangerouslySetInnerHTML={{
          __html: `<div id="mc_embed_shell">
            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
            <style type="text/css">
              #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}
              /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
            </style>
            <div id="mc_embed_signup">
              <form action="https://cpsinsurance.us2.list-manage.com/subscribe/post?u=57bf072d5c356280c259d2202&amp;id=5f4b86ad69&amp;f_id=00c1c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                <div id="mc_embed_signup_scroll"><h2>Subscribe</h2>
                  <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                  <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
                  <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display: none;"></div>
                    <div class="response" id="mce-success-response" style="display: none;"></div>
                  </div>
                  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_57bf072d5c356280c259d2202_5f4b86ad69" tabindex="-1" value=""></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
                </div>
              </form>
            </div>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[6]='CITY';ftypes[6]='text';fnames[7]='STATE';ftypes[7]='text';fnames[8]='ZIP';ftypes[8]='number';fnames[9]='PHONE';ftypes[9]='text';fnames[19]='CPSOFFICE';ftypes[19]='text';fnames[20]='MMERGE20';ftypes[20]='text';fnames[21]='MMERGE21';ftypes[21]='text';fnames[14]='MMERGE14';ftypes[14]='text';fnames[1]='MMERGE1';ftypes[1]='text';fnames[2]='MMERGE2';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
          </div>
        `,
        }}
      />
    </section>
  )
}
