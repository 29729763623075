import * as React from 'react'
import * as css from './register.module.css'
import DefaultLayout from 'layouts/Default'
import RegisterForm from 'components/pg-register/RegisterForm'
import NewsletterSignup from 'components/common/NewsletterSignup'
import clsx from 'clsx'

const meta = {
  title: 'Register',
  description: '',
}

export default function RegisterPage() {
  return (
    <DefaultLayout {...meta}>
      <section className="container-sm padding">
        <header className={clsx(css.header, 'richtext')}>
          <h1 className="h1">Grow your revenue anytime, anywhere.</h1>
          <p>
            Register now to gain access to all the tools you need to market and
            streamline your business from beginning to end.
          </p>
        </header>
        <RegisterForm />
      </section>

      <NewsletterSignup />
    </DefaultLayout>
  )
}
